import React from "react";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";
import News from "../components/news";

const BlogList = ({ pageContext: { itemList } }) => {
  return (
    <Layout>
      <Seo title="Últimas noticias" />
      <Header />
      <main className="news-list">
        <h1>Noticias</h1>

        <News
          postsList={itemList}
          limit={itemList.data.length}
          withImage={true}
          home={false}
        />
      </main>
    </Layout>
  );
};

export default BlogList;
