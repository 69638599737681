import React from "react";
import { Link } from "gatsby";

import { formatURL } from "../utils/helpers";

const News = (
  { postsList, home = true, limit = 2, withImage = false },
  ...props
) => {
  return (
    <>
      <div className={`news-feed${home ? " home" : ""}`}>
        {postsList.data.slice(0, limit).map((post, index) => {
          return (
            <article key={index}>
              <Link to={`/noticia/${formatURL(post.title)}`}>
                {withImage && <img src={post.image} alt="" />}
                <h2>{post.title}</h2>
                <p>{post.description}</p>
                <p className="btn-view-more">Ver más</p>
              </Link>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default News;
